import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Heading,
  Text,
  CreateAccount,
  Width,
  List,
  ListItem,
  Span,
  ContactLine,
} from "@/components/index"

export default function SignUp() {
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Try Sonora’s Guitar Intensive Free for 7 Days"
        description="Find your breakthrough on guitar. Sign up for a free 7-day trial of Sonora’s innovative guitar intensive course for intermediate guitar players."
      />
      <Box
        bg="agate"
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={["16.5rem", null, null, "9.6rem"]}
        pb={["4.8rem", null, null, "0"]}
      >
        <Width
          display="flex"
          flexDirection={["column", "row"]}
          pt={["0", null, null, "17.2rem"]}
          pb={["0", null, null, "13.9rem"]}
        >
          <Box
            width={["100%", 5 / 12]}
            textAlign="left"
            mb={["6rem", null, null, "0"]}
            px={["2.4rem", "0"]}
          >
            <Heading level="1" mb="2rem">
              Start your free 7-day trial
            </Heading>
            <Text mb="2.3rem">
              Includes{" "}
              <Span fontWeight="600" color="amethyst">
                Sonora Core
              </Span>{" "}
              features:
            </Text>
            <List bullets="checkmark">
              <ListItem>Core platform and curriculum</ListItem>
              <ListItem>Weekly live Q&A's</ListItem>
              <ListItem>Community groups and events</ListItem>
            </List>
            <Text mt="3rem" mb="2.3rem">
              ...plus{" "}
              <Span fontWeight="600" color="amethyst">
                Sonora Fellowship
              </Span>{" "}
              features:
            </Text>
            <List bullets="checkmark" mb="2.3rem">
              <ListItem>1 FREE skill assessment with our team</ListItem>
              <ListItem>Unlimited 1-on-1 feedback</ListItem>
              <ListItem>Masterclass access</ListItem>
            </List>
            <Text level={2} mb="2.3rem" mt="2.3rem">
              There’s no obligation or commitment necessary, and you can cancel
              at any time.
            </Text>
            <ContactLine />
          </Box>
          <Box width={["100%", 6 / 12]} textAlign="left" ml={["0", "auto"]}>
            <CreateAccount />
            <Box display={["flex", "none"]} mt="3.6rem" justifyContent="center">
              <ContactLine textAlign="center" />
            </Box>
          </Box>
        </Width>
      </Box>
    </Layout>
  )
}
